import * as React from "react"
import { CallToAction } from "../callToAction"
import { headingSplitter, blockToContent } from "../../helpers"
import { getImageUrl } from "../../utils/getImageUrl"

export function FullScreen({ cta, hero }) {
  const bgUrl = hero.bgImage
    ? hero.bgImage
    : getImageUrl(hero.bgimage?.asset?._ref)

  const altText = hero.bgImage ? hero.altText : hero.bgimage?.alttext
  return (
    <>
      <div
        className={`relative ${
          hero.contentPosition === "below" ? "hero" : ""
        } bg-white overflow-hidden`}
      >
        <div className="max-w-screen mx-auto">
          <div className="hero_block">
            <div className="relative sm:mt-6 md:mt-8 lg:mt-10 xl:mt-14">
              <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
              <div className="mx-auto">
                <div className="relative sm:shadow-xl sm:overflow-hidden">
                  <div className="absolute inset-0 hero_bg">
                    {(hero.bgImage || hero.bgimage?.asset) && (
                      <img
                        src={bgUrl}
                        className="absolute top-0 left-0 w-full h-full object-cover"
                        alt={altText || ""}
                      />
                    )}
                    <div className="absolute inset-0 bg-gray-500/30 mix-blend-multiply" />
                  </div>

                  <div className="relative px-4 hero_content py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                    <h1 className="flex-wrap flex justify-center text_shadow gap-3 text-center text-4xl font-extrabold text-white tracking-tight sm:text-5xl lg:text-6xl">
                      {headingSplitter(hero?.heading)}
                    </h1>
                    <p className="mt-6 max-w-lg mx-auto text-center text-lg font-bold text_shadow text-white sm:max-w-3xl">
                      {hero?.subheading &&
                        blockToContent(hero.subheading[0]?.children)}
                    </p>
                    <div className="mt-6 md:mt-10 max-w-sm mx-auto sm:max-w-none sm:flex hero_button sm:justify-center">
                      <CallToAction {...cta} hero={hero} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FullScreen
